import React, {useEffect, useState} from "react";
import {Row, Col, Form, Card} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {ButtonPrimary} from "../../Buttons/index.js";
import Swal from "sweetalert2";
import {useAuth} from "../../../context/authContext";
import couponService from "../../../services/couponService";
import Select from "../../Select";
import partnersService from "../../../services/partnersService";
import categoriesService from "../../../services/categoriesService";

function CreateCoupon({setLoading}) {
    const navigate = useNavigate();

    const {user} = useAuth();
    const [isLimited, setIsLimited] = useState(false);
    const [partners, setPartners] = useState([]);
    const [categories, setCategories] = useState([]);
    const [coupon, setCoupon] = useState({
        title: "",
        category: "",
        description: "",
        image: {
            src: "",
            title: ""
        },
        createdby: user.email,
        createdate: new Date(),
        lastupdate: new Date(),
        updatedby: user.email,
        limitedByDate: false,
        link: "",
        partnerId: "",
        subtitle: "",
        validFrom: "",
        validUntil: "",
        notes: ""
    });

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setCoupon((prevcoupon) => ({
            ...prevcoupon,
            [name]: value,
        }));
    };

    const handleSwitchChange = (event) => {
        setIsLimited(!isLimited);
        const {name, value} = event.target;

        if (value === "on") {
            setCoupon((prevcoupon) => ({
                ...prevcoupon,
                [name]: true,
            }));
        } else {
            setCoupon((prevcoupon) => ({
                ...prevcoupon,
                [name]: false,
            }));
        }
    }

    const transformImageToBase64 = (files) => {
        let base64List = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            base64List.push(
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64String = reader.result;
                        resolve(base64String);
                    };
                    reader.onerror = (error) => reject(error);
                    reader.readAsDataURL(file);
                })
            );
        }

        return Promise.all(base64List);
    };

    const validateSizeFile = (file) => {
        const maxSizeInBytes = 2200000;
        const size = file.size;

        if (size > maxSizeInBytes) {
            return false;
        }

        return true;
    };

    const handleInputChangeFile = async (event) => {
        const {name, files} = event.target;

        try {
            if (files.length > 0) {
                if (!validateSizeFile(files[0])) {
                    event.target.value = "";

                    Swal.fire(
                        "Tamanho excedido",
                        "O arquivo deve ter no máximo 2MB",
                        "error"
                    );
                    return false;
                }

                const base64Image = await transformImageToBase64(files);
                const fileName = files[0].name;

                setCoupon((prevcoupon) => ({
                    ...prevcoupon,
                    [name]: {
                        src: base64Image[0],
                        title: fileName
                    },
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const submitData = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await couponService.createCoupon(coupon);

            setLoading(false);

            Swal.fire({
                title: "Desconto criado",
                text: "O desconto foi criado com sucesso",
                icon: "success",
            }).then(() => {
                navigate("/coupons");
            });
        } catch (error) {
            setLoading(false);
            Swal.fire(
                "Ocorreu um erro",
                "Houve um erro ao criar o desconto, tente novamente",
                "error"
            );
        }
    };

    const fetchPartners = async () => {
        setLoading(true);
        try {
            const params = {
                page: 1,
                limit: 1000,
                sortBy: "name",
                order: "asc",
                name: "",
            };
            const response = await partnersService.fetchData(params);

            const formattedData = response.data.map((item) => {
                return {label: item.name, value: item.id};
            });
            setPartners(formattedData);
        } catch (error) {
            Swal.fire("Erro!", error.message, "error");
        }
    };

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const params = {
                page: 1,
                limit: 1000,
                sortBy: "name",
                order: "asc",
                name: "",
            };
            const response = await categoriesService.fetchData(params);

            const formattedData = response.data.map((item) => {
                return {label: item.category, value: item.id};
            });
            setCategories(formattedData);
        } catch (error) {
            Swal.fire("Erro!", error.message, "error");
        }
    };

    const init = async () => {
        setLoading(true);
        await fetchPartners();
        await fetchCategories();
        setLoading(false);
    }

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Card className="rounded-0 card-form m-75">
                <Row>
                    <Col xs={12} md={12} className="padding-card">
                        <h2 className="text-uppercase font-semi-bold mb-0">
                            Informações do Desconto
                        </h2>
                        <p className="font-light color-gray mb-4">
                            Coloque as informações do seu Desconto abaixo.
                        </p>
                        <Form onSubmit={submitData}>
                            <Row className="mb-3">
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Nome
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            placeholder="Digite o título do desconto"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Legenda
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="subtitle"
                                            placeholder="Digite a legenda do desconto"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Descrição
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            placeholder="Digite a descrição do desconto"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Nota
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="notes"
                                            placeholder="Digite a nota do desconto"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Link
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="link"
                                            placeholder="Digite o link do desconto"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </Col>
                                {partners && (
                                    <Col xs={12} className="mb-3">
                                        <Form.Group className="mb-2">
                                            <Form.Label className="text-uppercase">
                                                Estabelecimento
                                                <sup className="ms-1 text-danger fw-bold">*</sup>
                                            </Form.Label>
                                            <Select
                                                name="partnerId"
                                                options={partners}
                                                onChange={handleInputChange}
                                                required={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                                {categories && (
                                    <Col xs={12} className="mb-3">
                                        <Form.Group className="mb-2">
                                            <Form.Label className="text-uppercase">
                                                Categoria
                                                <sup className="ms-1 text-danger fw-bold">*</sup>
                                            </Form.Label>
                                            <Select
                                                name="category"
                                                options={categories}
                                                onChange={handleInputChange}
                                                required={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Imagem do Desconto
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="file"
                                            name="image"
                                            onChange={handleInputChangeFile}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Oferta limitada por data
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label=""
                                            name="limitedByDate"
                                            checked={isLimited}
                                            onChange={handleSwitchChange}
                                        />
                                    </Form.Group>
                                </Col>
                                {isLimited && (
                                    <Col xs={12} className="mb-3">
                                        <Form.Group className="mb-2">
                                            <Form.Label className="text-uppercase">
                                                Válido de
                                                <sup className="ms-1 text-danger fw-bold">*</sup>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="validFrom"
                                                placeholder="Válido de"
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                                {isLimited && (
                                    <Col xs={12} className="mb-3">
                                        <Form.Group className="mb-2">
                                            <Form.Label className="text-uppercase">
                                                Válido até
                                                <sup className="ms-1 text-danger fw-bold">*</sup>
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="validUntil"
                                                placeholder="Válido até"
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary type="submit" btnText="SALVAR"/>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default CreateCoupon;
