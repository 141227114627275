import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import notificationsBodyService from "../../../services/notificationsBodyService";
import establishmentService from "../../../services/establishmentService";
import discountsService from "../../../services/discountsService";
import { ButtonPrimary } from "../../Buttons/index.js";
import Swal from "sweetalert2";
import { useAuth } from "../../../context/authContext";
import Select from "./../../Select";

function CreateNotification({ setLoading }) {
  const navigate = useNavigate();

  const { user } = useAuth();
  const [establishments, setEstablishments] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [requiredEstablishment, setRequiredEstablishment] = useState(false);
  const [requiredDiscount, setRequiredDiscount] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    description: "",
    image: "",
    createdBy: user.email,
    updatedBy: user.email,
    url: "",
  });

  const handleChangeNotificationSubType = (event) => {
    const { name, value } = event.target;

    if (name === "establishment") {
      setNotification((prevNotification) => ({
        ...prevNotification,
        url: `clubecult://establishments/detail/${value}`,
      }));
    }

    if (name === "discount") {
      setNotification((prevNotification) => ({
        ...prevNotification,
        url: `clubecult://discounts/detail/${value}`,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "type") {
      switch (value) {
        case "establishment":
          setRequiredDiscount(false);
          setRequiredEstablishment(true);
          break;
        case "discount":
          setRequiredDiscount(true);
          setRequiredEstablishment(false);
          break;
        default:
          setRequiredDiscount(false);
          setRequiredEstablishment(false);
          setNotification((prevNotification) => ({
            ...prevNotification,
            url: "clubecult://notifications/detail",
          }));
          break;
      }
    }

    setNotification((prevNotification) => ({
      ...prevNotification,
      [name]: value,
    }));
  };

  const transformImageToBase64 = (files) => {
    let base64List = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      base64List.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            resolve(base64String);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        })
      );
    }

    return Promise.all(base64List);
  };

  const validateSizeFile = (file) => {
    const maxSizeInBytes = 2200000;
    const size = file.size;

    if (size > maxSizeInBytes) {
      return false;
    }

    return true;
  };

  const handleInputChangeFile = async (event) => {
    const { name, files } = event.target;

    try {
      if (files.length > 0) {
        if (!validateSizeFile(files[0])) {
          event.target.value = "";

          Swal.fire(
            "Tamanho excedido",
            "O arquivo deve ter no máximo 2MB",
            "error"
          );
          return false;
        }

        const base64Image = await transformImageToBase64(files);

        setNotification((prevNotifcation) => ({
          ...prevNotifcation,
          [name]: base64Image[0],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitData = async (event) => {
    event.preventDefault();

    const resultAlert = await Swal.fire({
      title: "Criar Notificação",
      text: `Tem certeza que deseja criar a notificação? Ao criar a notificação ela erá disparada automaticamente aos usuários.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5c40",
      confirmButtonText: "Sim",
    });
    if (resultAlert.isConfirmed) {
      setLoading(true);

      try {
        await notificationsBodyService.createNotification(notification);
  
        setLoading(false);
  
        Swal.fire({
          title: "Notificação criada",
          text: "A notificação foi criada e disparada aos usuários",
          icon: "success",
        }).then(() => {
          navigate("/notifications");
        });
      } catch (error) {
        setLoading(false);
        Swal.fire(
          "Ocorreu um erro",
          "Houve um erro ao criar a notificação, tente novamente",
          "error"
        );
      }
    } else {
      return false;
    }
  };

  const refreshItems = async () => {
    setLoading(false);
  };

  const fetchEstablishments = async () => {
    setLoading(true);
    try {
      const params = {
        page: 1,
        limit: 1000,
        sortBy: "establishmentName",
        order: "establishmentName",
        name: "",
      };
      const response = await establishmentService.fetchData(params);

      const formattedData = response.data.map((item) => {
        return {label: item.establishmentName, value: item.id};
      });
      setEstablishments(formattedData);
    } catch (error) {
      Swal.fire("Erro!", error.message, "error");
    } finally {
      refreshItems();
    }
  };

  const fetchDiscounts = async () => {
    setLoading(true);
    try {
      const params = {
        page: 1,
        limit: 1000,
        sortBy: "title",
        order: "title",
        name: "",
      };
      const response = await discountsService.fetchData(params);

      const formattedData = response.data.map((item) => {
        return {label: item.title, value: item.id};
      });
      setDiscounts(formattedData);
    } catch (error) {
      Swal.fire("Erro!", error.message, "error");
    } finally {
      refreshItems();
    }
  };

  const init = async () => {
    await fetchEstablishments();
    await fetchDiscounts();
  }

  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="rounded-0 card-form m-75">
        <Row>
          <Col xs={12} md={12} className="padding-card">
            <h2 className="text-uppercase font-semi-bold mb-0">
              Informações da Notificação
            </h2>
            <p className="font-light color-gray mb-4">
              Coloque as informações da sua notificação abaixo.
            </p>
            <Form onSubmit={submitData}>
              <Row className="mb-3">
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Título
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Digite o título da notificação"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Descrição
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      placeholder="Digite a descrição da notificação"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Imagem da Notificação
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={handleInputChangeFile}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Tipo da notificação
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Select
                      name="type"
                      options={[
                        {
                          label: "Notificação",
                          value: "notification",
                        },
                        {
                          label: "Estabelecimento",
                          value: "establishment",
                        },
                        {
                          label: "Desconto",
                          value: "discount",
                        },
                      ]}
                      onChange={handleInputChange}
                      required={true}
                    />
                  </Form.Group>
                </Col>
                {requiredEstablishment && establishments && (
                  <Col xs={12} className="mb-3">
                    <Form.Group className="mb-2">
                      <Form.Label className="text-uppercase">
                        Qual estabelecimento?
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Select
                        name="establishment"
                        options={establishments}
                        onChange={handleChangeNotificationSubType}
                        required={requiredEstablishment}
                      />
                    </Form.Group>
                  </Col>
                )}
                {requiredDiscount && discounts && (
                  <Col xs={12} className="mb-3">
                    <Form.Group className="mb-2">
                      <Form.Label className="text-uppercase">
                        Qual desconto?
                        <sup className="ms-1 text-danger fw-bold">*</sup>
                      </Form.Label>
                      <Select
                        name="discount"
                        options={discounts}
                        onChange={handleChangeNotificationSubType}
                        required={requiredDiscount}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col
                  xs={12}
                  className="d-flex justify-content-center justify-content-md-end"
                >
                  <ButtonPrimary type="submit" btnText="SALVAR" />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default CreateNotification;
