import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import { WidgetLabel } from "../../components/Cards/Labels";
import Loading from "../../components/Loading";
import { useAuth } from "../../context/authContext";
import dashboardService from "../../services/dashboardService";

function Dashboard() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);

  const fetchCounts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await dashboardService.fetchCounts();
      setSubscriptions(response);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar as assinaturas:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    document.title = "Clube Cult - Web APP - Dashboard";
    fetchCounts();
  }, [fetchCounts]);

  return (
    <>
      <Sidebar pageName="Dashboard" pageUrl="/dashboard">
        <Container fluid className="p-4">
          <Row>
            <Col xs={12}>
              <h2>
                Bem vindo(a),{" "}
                <strong className="color-primary">{user?.fullName}</strong>! 👋
              </h2>
              <h4 className="font-light">Muito bom te ter de volta conosco!</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <h4>Assinaturas</h4>
            </Col>
          </Row>
          <Row className="mt-2">
            <WidgetLabel
              title="Assinaturas do Dia"
              value={subscriptions.dailyCount || 0}
              icon="group"
              link={`/subscriptions`}
            />
            <WidgetLabel
              title="Assinaturas Totais"
              value={subscriptions.totalCount || 0}
              icon="groups"
              link={`/subscriptions`}
            />
          </Row>
          <hr className="hr-25" />
          <Row className="mt-3">
            <WidgetLabel
              title="Assinantes do Mês"
              value={subscriptions.monthlyCount || 0}
              icon="groups"
              link={`/subscriptions`}
            />
            <WidgetLabel
              title="Cancelamentos do Mês"
              value={subscriptions.monthlyCancellationsCount || 0}
              icon="group_remove"
              link={`/subscriptions`}
            />
          </Row>
          <hr className="hr-25" />
          <Row className="mt-3">
            <WidgetLabel
              title="Assinantes Mensais"
              value={subscriptions.monthlyActiveCount || 0}
              icon="calendar_today"
              link={`/subscriptions`}
            />
            <WidgetLabel
              title="Assinantes Trimestrais"
              value={subscriptions.semestralActiveCount || 0}
              icon="date_range"
              link={`/subscriptions`}
            />
            <WidgetLabel
              title="Assinantes Anuais"
              value={subscriptions.annualActiveCount || 0}
              icon="calendar_month"
              link={`/subscriptions`}
            />
          </Row>
          <Row className="mt-5">
            <Col xs={12}>
              <h4>Cadastros</h4>
            </Col>
          </Row>
          <Row className="mt-3">
            <WidgetLabel
              title="Descontos Utilizados"
              value={subscriptions.usedDiscounts || 0}
              icon="add_shopping_cart"
              link={`/used-discounts`}
            />
            <WidgetLabel
              title="Parceiros Cadastrados"
              value={subscriptions.activeEstablishments || 0}
              icon="storefront"
              link={`/establishments`}
            />
            <WidgetLabel
              title="Experiências Cadastradas"
              value={subscriptions.activeCoupons || 0}
              icon="percent"
              link={`/coupons`}
            />
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Dashboard;
