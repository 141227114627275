import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";
import Loading from "../../../components/Loading";
import establishmentService from "../../../services/establishmentService";
import Swal from "sweetalert2";
import FilterBar from "../../../components/FiltersBar";
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListHeaderSortItem,
  CardListBody,
  CardListBodyItem,
  CardListBodyItemGroupButton,
} from "../../../components/CardList";
import { limitString } from "../../../config/utils";
import { ButtonFloating } from "../../../components/Buttons";
import { Link } from "react-router-dom";

function Establishments() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: "createDate", order: "desc" },
    limit: 20,
    searchName: "",
  });

  const filterConfig = [
    {
      type: "text",
      label: "Pesquisar pelo nome do parceiro",
      name: "searchName",
      size: 3,
    },
    {
      type: "select",
      label: "Número de itens",
      name: "limit",
      options: [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      size: 2,
    },
  ];

  const prevPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const handleInputSearch = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const refreshItems = async () => {
    setLoading(false);
  };

  const fetchEstablishments = useCallback(async () => {
    setLoading(true);
    try {
      const { page, limit, sort, searchName } = filters;
      const params = {
        page,
        limit,
        sortBy: sort.sortBy,
        order: sort.order,
        name: searchName,
      };
      const response = await establishmentService.fetchData(params);
      setData(response.data);
      setDataInfo({ ...response });
    } catch (error) {
      Swal.fire("Erro!", error.message, "error");
    } finally {
      refreshItems();
    }
  }, [filters]);

  useEffect(() => {
    document.title = "Clube Cult - Web APP - Parceiros";
    fetchEstablishments();
  }, [fetchEstablishments]);

  const handleSort = (sortKey) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        sort: {
          sortBy: sortKey,
          order:
            prevFilters.sort.sortBy === sortKey &&
            prevFilters.sort.order === "asc"
              ? "desc"
              : "asc",
        },
      };
      return newFilters;
    });
  };

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: "Deletar parceiro",
      text: `Tem certeza que deseja deletar o parceiro "${name}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Sim, deletar",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: "Deletando...",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await establishmentService.deleteEstablishment(id);

        Swal.close();
        fetchEstablishments();

        await Swal.fire({
          title: "Deletado!",
          text: "O parceiro foi deletado com sucesso",
          icon: "success",
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Erro!",
          text: "Erro desconhecido. Não foi possível realizar a ação",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      <Sidebar pageName="Parceiros" pageUrl="/establishments">
        <Container fluid>
          <FilterBar
            config={filterConfig}
            onFilterChange={handleInputSearch}
            filters={filters}
          />
          <Row>
            <Col>
              <CardList
                page={parseInt(dataInfo.page)}
                data={data}
                pages={dataInfo.totalPages}
                callbackNext={nextPage}
                callbackPrev={prevPage}
              >
                <CardListHeader className="bg-color-light-gray">
                  <Row>
                    <CardListHeaderSortItem
                      xs={12}
                      lg={4}
                      onSort={handleSort}
                      sortKey={"establishmentName"}
                    >
                      Nome
                    </CardListHeaderSortItem>
                    <CardListHeaderItem xs={12} lg={3}>
                      Imagem
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={3}>
                      QR Code
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={2} className="text-center">
                      Gerenciar
                    </CardListHeaderItem>
                  </Row>
                </CardListHeader>
                {data.map((data, index) => (
                  <CardListBody key={index}>
                    <Row>
                      <CardListBodyItem
                        xs={12}
                        lg={4}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Nome:"}
                        value={limitString(data.establishmentName, 40)}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Imagem:"}
                        value={
                          <Image
                            src={data.establishmentImage}
                            thumbnail
                            width="150"
                          />
                        }
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Imagem:"}
                        value={
                          <Image
                            src={data.establishmentQrCode}
                            thumbnail
                            width="150"
                          />
                        }
                      />
                      <CardListBodyItemGroupButton
                        xs={12}
                        lg={2}
                        className="d-inline-flex align-items-center justify-content-between"
                      >
                        <Button
                          as={Link}
                          to={`/establishments/edit/${data.id}`}
                        >
                          Editar
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() =>
                            handleDelete(data.id, data.establishmentName)
                          }
                        >
                          Excluir
                        </Button>
                      </CardListBodyItemGroupButton>
                    </Row>
                  </CardListBody>
                ))}
                {data.length === 0 ? (
                  <>
                    <CardListBody>
                      <Col
                        xs={12}
                        className="d-inline-flex align-items-center text-muted small justify-content-center"
                      >
                        Nenhum item encontrado.
                      </Col>
                    </CardListBody>
                  </>
                ) : (
                  <></>
                )}
              </CardList>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
      <ButtonFloating
        icon="add"
        style={{ borderRadius: "50%", width: "60px", height: "60px" }}
        btnColor="primary"
        link="/establishments/create"
      />
    </>
  );
}

export default Establishments;
