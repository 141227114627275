import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";
import Loading from "../../../components/Loading";
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListHeaderSortItem,
  CardListBody,
  CardListBodyItem,
  CardListBodyItemOptions,
} from "../../../components/CardList";
import FilterBar from "../../../components/FiltersBar";
import Swal from "sweetalert2";
import subsService from "../../../services/subscriptionService";
import {
  dateHourFormat,
  convertTimestampToDate,
  limitString,
} from "../../../config/utils";

function Subscriptions() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: "name", order: "desc" },
    limit: 20,
    searchName: "",
    status: "",
    createDate: "",
  });

  const filterConfig = [
    {
      type: "text",
      label: "Pesquisar pelo nome do usuário ou e-mail",
      name: "searchName",
      size: 3,
    },
    {
      type: "select",
      label: "Número de itens",
      name: "limit",
      options: [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "250", value: 250 },
        { label: "500", value: 500 },
      ],
      size: 2,
    },
    {
      type: "select",
      label: "Filtrar por status",
      name: "status",
      options: [
        { label: "Selecionar...", value: "" },
        { label: "Ativo", value: "ativo" },
        { label: "Inativo", value: "inativo" },
      ],
      size: 2,
    },
    {
      type: "date",
      label: "Data de inclusão",
      name: "createDate",
      size: 3,
    },
  ];

  const refreshItems = async () => {
    setLoading(false);
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const { page, limit, sort, searchName, status, createDate } = filters;
      const params = {
        page,
        limit,
        status,
        createDate,
        sortBy: sort.sortBy,
        order: sort.order,
        name: searchName,
      };
      const response = await subsService.fetchData(params);
      setData(response.data);
      setDataInfo({ ...response });
    } catch (error) {
      Swal.fire("Erro!", error.message, "error");
    } finally {
      refreshItems();
    }
  }, [filters]);

  useEffect(() => {
    document.title = "Clube Cult - Web APP - Assinaturas";
    fetchUsers();
  }, [fetchUsers]);

  const handleSort = (sortKey) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        sort: {
          sortBy: sortKey,
          order:
            prevFilters.sort.sortBy === sortKey &&
            prevFilters.sort.order === "asc"
              ? "desc"
              : "asc",
        },
      };
      return newFilters;
    });
  };

  const prevPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const handleInputSearch = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const deleteData = async (data) => {
    const result = await Swal.fire({
      title: "Atenção!",
      text: `Deseja realmente ${
        new Date(data.activeUntil) > new Date() ? "ativar" : "inativar"
      } a assinatura do usuário?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      dangerMode: true,
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        await subsService.deleteSubscription(data.email);
        await fetchUsers();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: `Erro ao ${
            new Date(data.activeUntil) > new Date() ? "ativar" : "inativar"
          } assinatura do usuário, tente novamente mais tarde.`,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Sidebar pageName="Assinaturas" pageUrl="/subscriptions">
        <Container fluid>
          <FilterBar
            config={filterConfig}
            onFilterChange={handleInputSearch}
            filters={filters}
          />
          <Row>
            <Col>
              <CardList
                page={parseInt(dataInfo.page)}
                data={data}
                pages={dataInfo.totalPages}
                callbackNext={nextPage}
                callbackPrev={prevPage}
              >
                <CardListHeader className="bg-color-light-gray">
                  <Row>
                    <CardListHeaderSortItem
                      xs={12}
                      lg={4}
                      onSort={handleSort}
                      sortKey={"name"}
                    >
                      Nome
                    </CardListHeaderSortItem>
                    <CardListHeaderSortItem
                      xs={12}
                      lg={3}
                      onSort={handleSort}
                      sortKey={"email"}
                    >
                      E-mail
                    </CardListHeaderSortItem>
                    <CardListHeaderSortItem
                      xs={12}
                      lg={2}
                      onSort={handleSort}
                      sortKey={"activeUntil"}
                    >
                      Ativa até
                    </CardListHeaderSortItem>
                    <CardListHeaderItem xs={12} lg={1}>
                      Status
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={2} className="text-center">
                      Gerenciar
                    </CardListHeaderItem>
                  </Row>
                </CardListHeader>

                {data.map((data, index) => (
                  <CardListBody key={index}>
                    <Row>
                      <CardListBodyItem
                        xs={12}
                        lg={4}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Nome:"}
                        value={limitString(data.name, 40)}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"E-mail:"}
                        value={data.email}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={2}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Ativa até:"}
                        value={dateHourFormat(data.activeUntil)}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={1}
                        className={`d-inline-flex align-items-center small fw-bold ${
                          convertTimestampToDate(data.activeUntil) > new Date()
                            ? "text-success"
                            : "text-danger"
                        }`}
                        title={"Status:"}
                        value={
                          convertTimestampToDate(data.activeUntil) > new Date()
                            ? "Ativa"
                            : "Inativa"
                        }
                      />
                      <CardListBodyItemOptions
                        xs={12}
                        lg={2}
                        className="d-inline-flex align-items-center justify-content-center"
                      >
                        <Dropdown.Item
                          className={`font-semi-bold text-center ${
                            new Date(data.activeUntil) > new Date()
                              ? "text-danger"
                              : "text-success "
                          }`}
                          onClick={() => deleteData(data)}
                        >
                          {new Date(data.activeUntil) > new Date()
                            ? "Inativar assinatura"
                            : "Ativar assinatura"}
                        </Dropdown.Item>
                      </CardListBodyItemOptions>
                    </Row>
                  </CardListBody>
                ))}
                {data.length === 0 ? (
                  <>
                    <CardListBody>
                      <Col
                        xs={12}
                        className="d-inline-flex align-items-center text-muted small justify-content-center"
                      >
                        Nenhum item encontrado.
                      </Col>
                    </CardListBody>
                  </>
                ) : (
                  <></>
                )}
              </CardList>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Subscriptions;
