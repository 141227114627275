import React from "react";
import { Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export function WidgetLabel({
  title,
  value,
  icon,
  size = "auto",
  link,
  className,
}) {
  const nav = useNavigate();
  return (
    <Col xs={12} md={size}>
      <Card
        className={`label-card ${className}`}
        onClick={() => link && nav(link)}
      >
        <span className="material-icons-outlined material-icons icon">
          {icon}
        </span>
        <div>
          <h2 className="font-black mb-0">{value}</h2>
          <h5 className="mb-0">{title}</h5>
        </div>
      </Card>
    </Col>
  );
}
