import React, { useState } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import establishmentService from "../../../services/establishmentService";
import { ButtonPrimary } from "../../Buttons/index.js";
import Swal from "sweetalert2";
import { useAuth } from "../../../context/authContext";

function Create({ setLoading }) {
  const navigate = useNavigate();

  const { user } = useAuth();
  const [establishment, setEstablishment] = useState({
    establishmentName: "",
    establishmentDescription: "",
    establishmentPromotions: "",
    establishmentConditions: "",
    createdBy: user.email,
    createDate: new Date(),
    lastUpdate: new Date(),
    updatedBy: user.email,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEstablishment((prevEstablishment) => ({
      ...prevEstablishment,
      [name]: value,
    }));
  };

  const transformImageToBase64 = (files) => {
    let base64List = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      base64List.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            resolve(base64String);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        })
      );
    }

    return Promise.all(base64List);
  };

  const validateSizeFile = (file) => {
    const maxSizeInBytes = 2200000;
    const size = file.size;

    if (size > maxSizeInBytes) {
      return false;
    }

    return true;
  };

  const handleInputChangeFile = async (event) => {
    const { name, files } = event.target;

    try {
      if (files.length > 0) {
        if (!validateSizeFile(files[0])) {
          event.target.value = "";

          Swal.fire(
            "Tamanho excedido",
            "O arquivo deve ter no máximo 2MB",
            "error"
          );
          return false;
        }

        const base64Image = await transformImageToBase64(files);

        setEstablishment((prevEstablishment) => ({
          ...prevEstablishment,
          [name]: base64Image[0],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitData = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await establishmentService.createEstablishment(establishment);

      setLoading(false);

      Swal.fire({
        title: "Establecimento criado",
        text: "O QRCode do establecimento foi gerado, confira na página de edição do estabelecimento ou na página de listagem",
        icon: "success",
      }).then(() => {
        navigate("/establishments");
      });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Ocorreu um erro",
        "Houve um erro ao criar o estabelecimento, tente novamente",
        "error"
      );
    }
  };

  return (
    <>
      <Card className="rounded-0 card-form m-75">
        <Row>
          <Col xs={12} md={12} className="padding-card">
            <h2 className="text-uppercase font-semi-bold mb-0">
              Informações do Estabelecimento
            </h2>
            <p className="font-light color-gray mb-4">
              Coloque as informações do seu estabelecimento abaixo.
            </p>
            <Form onSubmit={submitData}>
              <Row className="mb-3">
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Nome
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="establishmentName"
                      placeholder="Digite o nome do estabelecimento"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Descrição
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="establishmentDescription"
                      placeholder="Digite a descrição do estabelecimento"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Promoção
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="establishmentPromotions"
                      placeholder="Digite a promoção do estabelecimento"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Condições
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="establishmentConditions"
                      placeholder="Digite as condições da promoção"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Imagem do Estabelecimento
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="establishmentImage"
                      onChange={handleInputChangeFile}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  className="d-flex justify-content-center justify-content-md-end"
                >
                  <ButtonPrimary type="submit" btnText="SALVAR" />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Create;
