import React, { useState } from "react";
import { Row, Col, Form, Card, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import establishmentService from "../../../services/establishmentService";
import { ButtonPrimary } from "../../Buttons/index.js";
import Swal from "sweetalert2";
import { useAuth } from "../../../context/authContext";

function Edit({ setLoading, data, id }) {
  const navigate = useNavigate();

  const { user } = useAuth();
  const [establishment, setEstablishment] = useState({
    establishmentName: data.establishmentName,
    establishmentDescription: data.establishmentDescription,
    establishmentPromotions: data.establishmentPromotions,
    establishmentConditions: data.establishmentConditions,
    establishmentImage: data.establishmentImage,
    lastUpdate: data.lastUpdate,
    updatedBy: user.email,
    id: id,
  });
  const [establishmentInitial] = useState({
    establishmentName: data.establishmentName,
    establishmentDescription: data.establishmentDescription,
    establishmentPromotions: data.establishmentPromotions,
    establishmentConditions: data.establishmentConditions,
    establishmentImage: data.establishmentImage,
    lastUpdate: data.lastUpdate,
    updatedBy: user.email,
    id: id,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEstablishment((prevEstablishment) => ({
      ...prevEstablishment,
      [name]: value,
    }));
  };

  const transformImageToBase64 = (files) => {
    let base64List = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      base64List.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;
            resolve(base64String);
          };
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        })
      );
    }

    return Promise.all(base64List);
  };

  const validateSizeFile = (file) => {
    const maxSizeInBytes = 2200000;
    const size = file.size;

    if (size > maxSizeInBytes) {
      return false;
    }

    return true;
  };

  const handleInputChangeFile = async (event) => {
    const { name, files } = event.target;

    try {
      if (files.length > 0) {
        if (!validateSizeFile(files[0])) {
          event.target.value = "";

          Swal.fire(
            "Tamanho excedido",
            "O arquivo deve ter no máximo 2MB",
            "error"
          );
          return false;
        }

        const base64Image = await transformImageToBase64(files);

        setEstablishment((prevEstablishment) => ({
          ...prevEstablishment,
          [name]: base64Image[0],
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitData = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { establishmentImage } = establishment;

    if (establishmentImage === undefined || establishmentImage === "") {
      setEstablishment((prevEstablishment) => ({
        ...prevEstablishment,
        establishmentImage: establishmentInitial.establishmentImage,
      }));
    }

    setEstablishment((prevEstablishment) => ({
      ...prevEstablishment,
      lastUpdate: new Date(),
    }));

    console.log(establishment);

    try {
      await establishmentService.updateEstablishment(establishment);

      setLoading(false);

      Swal.fire({
        title: "Establecimento atualizado",
        text: "Os dados do establecimento foram atualizados com sucesso",
        icon: "success",
      }).then(() => {
        navigate("/establishments");
      });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Ocorreu um erro",
        "Houve um erro ao atualizar o estabelecimento, tente novamente",
        "error"
      );
    }
  };

  return (
    <>
      <Card className="rounded-0 card-form m-75">
        <Row>
          <Col xs={12} md={12} className="padding-card">
            <h2 className="text-uppercase font-semi-bold mb-0">
              Editar Estabelecimento
            </h2>
            <p className="font-light color-gray mb-4">
              Coloque as informações do seu estabelecimento abaixo.
            </p>
            <Form onSubmit={submitData}>
              <Row className="mb-3">
                <Col xs={12} className="mb-3 d-flex justify-content-center">
                  <Image src={data.establishmentQrCode} thumbnail width="350" />
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Nome
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="establishmentName"
                      placeholder="Digite o nome do estabelecimento"
                      onChange={handleInputChange}
                      value={establishment.establishmentName || ""}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Descrição
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="establishmentDescription"
                      placeholder="Digite a descrição do estabelecimento"
                      onChange={handleInputChange}
                      value={establishment.establishmentDescription || ""}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Promoção
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="establishmentPromotions"
                      placeholder="Digite a promoção do estabelecimento"
                      onChange={handleInputChange}
                      value={establishment.establishmentPromotions || ""}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Condições
                      <sup className="ms-1 text-danger fw-bold">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="establishmentConditions"
                      placeholder="Digite as condições da promoção"
                      onChange={handleInputChange}
                      value={establishment.establishmentConditions || ""}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label className="text-uppercase">
                      Imagem do Estabelecimento
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="establishmentImage"
                      onChange={handleInputChangeFile}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} className="mb-3">
                  <Image src={data.establishmentImage} thumbnail width="250" />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  className="d-flex justify-content-center justify-content-md-end"
                >
                  <ButtonPrimary type="submit" btnText="SALVAR" />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Edit;
